import "./App.css";
import Footer from "./Components/Footer";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import Home from "./Pages/Home";
import About from "./Pages/About";
import Products from "./Pages/Products";
import Legal from "./Pages/Legal";
import Contact from "./Pages/Contact";
import Header from "./Components/Header";
import "./ProductSlider.css";
import "./styles.css";
import MyNavbar from "./Components/MyNavbar,";
import ScrollToTop from "./Components/ScrollToTop ";
import { useEffect, useState } from "react";
import Loader from "./Components/Loader";
import PridelactinSyrupCoa from "./Pages/PridelactinSyrupCoa";
import PridesicSP from "./Pages/PridesicSP";
import COA from "./Pages/COA";
import Zymepride from "./Pages/Zymepride";
import IronPrideSyrup from "./Pages/IronPrideSyrup";

function App() {
  const [loading, setLoading] = useState(false);
  const location = useLocation();

  useEffect(() => {
    setLoading(true);
    const handleScroll = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    };
    handleScroll();

    // Mimic async data fetching
    setTimeout(() => {
      setLoading(false);
    }, 300);
    // Adjust the duration to match your actual data fetching time
  }, [location]);

  return (
    <>
      {loading && <Loader />}
      <MyNavbar />
      <div id="box_wrapper">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/products" element={<Products />} />
          <Route path="/COA" element={<COA />} />

          <Route path="/legal" element={<Legal />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          {/* COA Pages */}
          <Route
            path="/Pridelactin-Syrup-COA"
            element={<PridelactinSyrupCoa />}
          />
          <Route path="/Pridesic-SP" element={<PridesicSP />} />
          <Route path="/Zymepride" element={<Zymepride />} />
          <Route path="/Iron-Pride-Syrup" element={<IronPrideSyrup />} />
        </Routes>
        <Footer />
      </div>
    </>
  );
}

const WrappedApp = () => (
  <Router>
    <ScrollToTop />
    <App />
  </Router>
);

export default WrappedApp;

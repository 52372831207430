import React from "react";
import ContactUs from "../Components/ContactUs";
import { Helmet } from "react-helmet-async";

function Contact() {
  return (
    <>
    <Helmet>
    <title>Contact NovaVision Medicare </title>
    <meta name="keywords" content="home, novavison medicare, react, website" />
  </Helmet>
      <section className="ls columns_padding_25 section_padding_top_100 section_padding_bottom_100">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <h3 className="module-header">Contact Form</h3>
            </div>
          </div>
          <div className="row">
            <div className="col-md-8 ">
              <form
                className="contact-form columns_padding_10 bottommargin_40"
                method="post"
                action="https://html.modernwebtemplates.com/pharma/"
              >
                <div className="row">
                  <div className="col-sm-6">
                    <div className="form-group bottommargin_0">
                      {" "}
                      <label for="name">
                        Full Name <span className="required">*</span>
                      </label>{" "}
                      <i
                        className="fa fa-user highlight2"
                        aria-hidden="true"
                      ></i>{" "}
                      <input
                        type="text"
                        aria-required="true"
                        size="30"
                        value=""
                        name="name"
                        id="name"
                        className="form-control"
                        placeholder="Full Name"
                      ></input>{" "}
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group bottommargin_0">
                      {" "}
                      <label for="phone">
                        Phone Number<span className="required">*</span>
                      </label>{" "}
                      <i
                        className="fa fa-phone highlight2"
                        aria-hidden="true"
                      ></i>{" "}
                      <input
                        type="text"
                        aria-required="true"
                        size="30"
                        value=""
                        name="phone"
                        id="phone"
                        className="form-control"
                        placeholder="Phone Number"
                      ></input>{" "}
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group bottommargin_0">
                      {" "}
                      <label for="email">
                        Email address<span className="required">*</span>
                      </label>{" "}
                      <i
                        className="fa fa-envelope highlight2"
                        aria-hidden="true"
                      ></i>{" "}
                      <input
                        type="email"
                        aria-required="true"
                        size="30"
                        value=""
                        name="email"
                        id="email"
                        className="form-control"
                        placeholder="Email Address"
                      ></input>{" "}
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group bottommargin_0">
                      {" "}
                      <label for="subject">
                        Subject<span className="required">*</span>
                      </label>{" "}
                      <i
                        className="fa fa-flag highlight2"
                        aria-hidden="true"
                      ></i>{" "}
                      <input
                        type="text"
                        aria-required="true"
                        size="30"
                        value=""
                        name="subject"
                        id="subject"
                        className="form-control"
                        placeholder="Subject"
                      ></input>{" "}
                    </div>
                  </div>
                  <div className="col-sm-12">
                    <div className="contact-form-message form-group bottommargin_0">
                      {" "}
                      <label for="message">Message</label>{" "}
                      <i
                        className="fa fa-comment highlight2"
                        aria-hidden="true"
                      ></i>{" "}
                      <textarea
                        aria-required="true"
                        rows="3"
                        cols="45"
                        name="message"
                        id="message"
                        className="form-control"
                        placeholder="Message"
                      ></textarea>{" "}
                    </div>
                  </div>
                  <div className="col-sm-12 bottommargin_0">
                    <div className="contact-form-submit topmargin_10">
                      {" "}
                      <button
                        type="submit"
                        id="contact_form_submit"
                        name="contact_submit"
                        className="theme_button color2 wide_button margin_0"
                      >
                        Send message
                      </button>{" "}
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div className="col-md-4 ">
              <ul className="list1 no-bullets no-top-border no-bottom-border">
                <li>
                  <div className="media">
                    <div className="media-left">
                      {" "}
                      <i className="rt-icon2-shop highlight2 fontsize_18"></i>{" "}
                    </div>
                    <div className="media-body">
                      <h5 className="media-heading grey">Postal Address:</h5>{" "}
                      Plot No. 68, Khasra No. 1680 Hanumant Enclave, Salempur,
                      Bahadrabad, Haridwar 249402
                    </div>
                  </div>
                </li>
                <li>
                  <div className="media">
                    <div className="media-left">
                      {" "}
                      <i className="rt-icon2-phone5 highlight2 fontsize_18"></i>{" "}
                    </div>
                    <div className="media-body">
                      <h5 className="media-heading grey">Phone:</h5> 8(800)
                      123-12345{" "}
                    </div>
                  </div>
                </li>

                <li>
                  <div className="media">
                    <div className="media-left">
                      {" "}
                      <i className="rt-icon2-mail highlight2 fontsize_18"></i>{" "}
                    </div>
                    <div className="media-body greylinks">
                      <h5 className="media-heading grey">Email:</h5>{" "}
                      info@novavision.in
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Contact;

import React from "react";
import Numbering from "../Components/Numbering";
import WhyChooseUs from "../Components/WhyChooseUs";
import { Helmet } from "react-helmet-async";

function Zymepride() {
  return (
    <>
    <Helmet>
        <title>Zymepride  NovaVision Medicare </title>
        <meta name="keywords" content="zymepride, novavison medicare, react, website" />
      </Helmet>
      <section class="page_breadcrumbs ds color parallax section_padding_top_75 section_padding_bottom_75">
        <div class="container">
          <div class="row">
            <div class="col-sm-12 text-center">
              <h2>Zymepride-COA</h2>
            </div>
          </div>
        </div>
      </section>

      <section
        id="about"
        className="ls section_padding_top_150 section_padding_bottom_150"
      >
        <div className="container">
          <div className="row">
            <div className="col-sm-12 text-left">
              <h2
                className="section_header"
                style={{ textAlign: "left !important" }}
              >
                Zymepride-COA{" "}
              </h2>
              <hr></hr>
              <img src="/images/COA/Zymepride.jpg" alt="" className="w-100" />
            </div>
          </div>
        </div>
      </section>

      <Numbering />
      <WhyChooseUs />
    </>
  );
}

export default Zymepride;

import React from "react";
import Numbering from "../Components/Numbering";
import WhyChooseUs from "../Components/WhyChooseUs";
import { Helmet } from "react-helmet-async";

function COA() {
  return (
    <>
    <Helmet>
    <title>COA NovaVision Medicare </title>
    <meta name="keywords" content="home, novavison medicare, react, website" />
  </Helmet>
      <section class="page_breadcrumbs ds color parallax section_padding_top_75 section_padding_bottom_75">
        <div class="container">
          <div class="row">
            <div class="col-sm-12 text-center">
              <h2>COA</h2>
            </div>
          </div>
        </div>
      </section>

      <section
        id="about"
        className="ls section_padding_top_150 section_padding_bottom_150"
      >
        <div className="container">
          <div className="row">
            <div className="col-md-4">
              <a
                href="/Pridelactin-Syrup-COA"
                target="_self"
                rel="noopener noreferrer"
              >
                <img
                  src="images/COA/Pridelactin.jpg"
                  alt=""
                  className="img-fluid"
                />
              </a>
              <h4 className="d-block text-center">Pridelactin</h4>
            </div>
            <div className="col-md-4">
              <a href="/Pridesic-SP" target="_self" rel="noopener noreferrer">
                <img
                  src="images/COA/Pridesic-SP.jpg"
                  alt=""
                  className="img-fluid"
                />
              </a>
              <h4 className="d-block text-center">Pridesic-SP</h4>
            </div>
            <div className="col-md-4">
              <a href="/Zymepride" target="_self" rel="noopener noreferrer">
                <img
                  src="images/COA/Zymepride.jpg"
                  alt=""
                  className="img-fluid"
                />
              </a>
              <h4 className="d-block text-center">Zymepride</h4>
            </div>
            <div className="col-md-4">
              <a
                href="/Iron-Pride-Syrup"
                target="_self"
                rel="noopener noreferrer"
              >
                {/* <img
                  src="images/COA/Zymepride.jpg"
                  alt=""
                  className="img-fluid"
                /> */}
              </a>
              <h4 className="d-block text-center">Iron Pride Syrup</h4>
            </div>
          </div>
        </div>
      </section>

      <Numbering />
      <WhyChooseUs />
    </>
  );
}

export default COA;

import React from "react";
import WelcomeOurSite from "../Components/WelcomeOurSite";
import OurProducts from "../Components/OurProducts";
import WhyChooseUs from "../Components/WhyChooseUs";
import Numbering from "../Components/Numbering";
import DevelopmentPipeline from "../Components/DevelopmentPipeline";
import Blog from "../Components/Blog";
import Testinomials from "../Components/Testinomials";
import ContactUs from "../Components/ContactUs";
import SliderBottom from "../Components/SliderBottom";
import Slider from "../Components/Slider";
import OwlCarsol from "../Components/OwlCarsol";
import ProductSlider from "../Components/ProductSlider";
import { Helmet } from "react-helmet-async";

function Home() {
  return (
    <> 
    <Helmet>
    <title>Home NovaVision Medicarre </title>
    <meta name="keywords" content="home, novavison medicare, react, website" />
  </Helmet>
      <OwlCarsol />
      <WelcomeOurSite />
      <ProductSlider />
      <WhyChooseUs />
      <Numbering />
      <DevelopmentPipeline />
      <ContactUs class="text-center" />
    </>
  );
}

export default Home;
